import RInfiniteScroll from "react-infinite-scroll-component";
import { GenericObject } from "../types/common";
import Spinner from "./Spinner";
import { PropsWithChildren } from "react";

type InfiniteScrollProps<T extends GenericObject> = PropsWithChildren & {
  data: T[];
  hasMore?: boolean;
  isFetching?: boolean;
  onNext: () => void;
};
export const InfiniteScroll = <T extends GenericObject>({
  data = [],
  hasMore = false,
  isFetching = false,
  onNext,
  children,
}: InfiniteScrollProps<T>) => {
  return (
    <div>
      <RInfiniteScroll
        className="overflow-y-hidden hide-scrollbar"
        dataLength={data.length}
        next={onNext}
        hasMore={!!hasMore}
        loader={
          isFetching && (
            <div className="mb-8 flex w-full items-center justify-center">
              <Spinner />
            </div>
          )
        }
        scrollThreshold={0.8} // Add threshold to trigger earlier
      >
        {children}
      </RInfiniteScroll>
    </div>
  );
};
